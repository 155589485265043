import React, { Component } from "react";
import "./App.css";
import 'font-awesome/css/font-awesome.min.css';

class App extends Component {

  state = {
    menuOpen: false
  }
  menuClickHandler = () => {
    this.setState({
      menuOpen: this.state.menuOpen ? false: true
    })
  }

  render() {

    return (
      <div className="App">
        <header className="App-header">
          <h1 className="hidden">William Gray</h1>
          <nav className={this.state.menuOpen ? 'open' : null} >
            <ul>
              <li className="navicon">
                <button onClick={this.menuClickHandler} >
                  <span className="hidden">Menu</span>
                </button>
              </li>
              <li>
                <a
                  className="fa fa-bandcamp fa-2x"
                  href="https://williamgray.bandcamp.com/"
                >
                  <span className="hidden">bandcamp</span>
                </a>
              </li>
              <li>
                <a
                  className="fa fa-soundcloud fa-2x"
                  href="https://soundcloud.com/william-gray"
                >
                  <span className="hidden">soundcloud</span>
                </a>
              </li>
              <li>
                <a
                  className="fa fa-xiami fa-2x"
                  href="http://i.xiami.com/williamgray?spm=a1z1s.3521865.23310001.9.FQ23Vw"
                >
                  <span className="hidden">Xiami</span>
                </a>
              </li>
              <li>
                <a
                  className="fa fa-youku fa-2x"
                  href="http://i.youku.com/i/UNTMzMDQ1MDc2"
                >
                  <span className="hidden">YouKu</span>
                </a>
              </li>
              <li>
                <a
                  className="fa fa-flickr fa-2x"
                  href="https://www.flickr.com/photos/williamgraymusic/"
                >
                  <span className="hidden">flickr</span>
                </a>
              </li>
              <li>
                <a
                  className="fa fa-facebook fa-2x"
                  href="https://www.facebook.com/williamgraymusic"
                >
                  <span className="hidden">facebook</span>
                </a>
              </li>
              <li>
                <a
                  className="fa fa-twitter fa-2x"
                  href="https://twitter.com/giantgraystacks"
                >
                  <span className="hidden">twitter</span>
                </a>
              </li>
              <li>
                <a
                  className="fa fa-vimeo fa-2x"
                  href="https://vimeo.com/user12478268"
                >
                  <span className="hidden">vimeo</span>
                </a>
              </li>
              <li>
                <a
                  className="fa fa-youtube fa-2x"
                  href="https://www.youtube.com/channel/UCJaAwQoEzDM-X4ogR8vptcw"
                >
                  <span className="hidden">youtube</span>
                </a>
              </li>
              <li>
                <a
                  className="fa fa-pencil fa-2x"
                  href="https://williamgraywriter.wordpress.com/"
                >
                  <span className="hidden">blog</span>
                </a>
              </li>
              <li>
                <a
                  className="fa fa-envelope-o fa-2x"
                  href="maito:williamgraymusic@gmail.com"
                >
                  <span className="hidden">email</span>
                </a>
              </li>
            </ul>
          </nav>
        </header>
      </div>
    );
  }
}

export default App;
